import React from "react";
import { Link } from "react-router-dom";
import Header from "./../components/Header";
import Footer from "./../components/Footer";

const AboutUs = () => {
  return (
    <>
      <Header />
      <div className="container my-5">
        <div className="row justify-content-center align-items-center">
          <div className="col-md-8">
            <h4 className="mb-4 text-left">About Us</h4>
            <p>
              Greetings from Pikkelz! <br></br>
              <br></br>
              At Pikkelz, we are passionate about the
              delectable flavors and wholesome health benefits of our culinary
              creations. Our journey began in 2023 with a simple yet profound
              aim: to craft delicious, nutritious food that enhances your
              well-being while ensuring utmost hygiene.
            </p>
            <p>
            <br></br>
              <h5 className="text-left">Our Story:</h5> 
              <br></br>
              Pikkelz emerged from a shared love for good food and a
              desire to redefine everyday dining experiences. Frustrated by the
              lack of readily available, high-quality options in the market, we
              embarked on a mission to tantalize taste buds without
              compromising on health or hygiene. Our journey led us to discover
              vibrant flavors in both vegetarian and non-vegetarian dishes, all
              meticulously prepared to meet the highest standards of hygiene.
            </p>
            <p><br></br>
              <h5 className="text-left">What Sets Pikkelz Apart:</h5> <br></br><h6>Taste and Flavor:</h6> 
              <br></br>We are dedicated to
              delighting your taste buds with every bite. Our culinary
              creations are bursting with rich, authentic flavors that elevate
              your dining experience to new heights. Health and Hygiene: Your
              well-being is our top priority. We adhere to strict hygiene
              practices throughout our production process to ensure that each
              meal is not only delicious but also safe and hygienic.
            </p>
            <p><br></br>
              <h5 className="text-left">Our Promise:</h5><br></br> <h6>Quality Assurance:</h6>
              <br></br> We maintain rigorous quality
              standards to deliver food that meets your expectations every
              time. From carefully sourced ingredients to meticulous
              preparation, we leave no stone unturned in ensuring the highest
              quality. Transparency: We believe in transparency and honesty.
              You deserve to know exactly what goes into your food, which is
              why we provide detailed information about the ingredients and
              preparation methods of each dish. Sustainability: We are
              committed to sustainable practices that benefit both you and the
              environment. From minimizing waste to supporting local producers,
              we strive to make responsible choices at every step of our
              journey.
            </p>
          </div>
          <div className="col-md-8 text-center mt-4">
            <button className="btn btn-success">
              <Link to="/" className="text-white text-decoration-none">
                Home page
              </Link>
            </button>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default AboutUs;
