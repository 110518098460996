import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const HeroSlider = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          arrows: false,
        },
      },
    ],
  };

  return (
    <div className="hero-slider">
      <Slider {...settings}>
       
        <div className="hero-item hero-item2">
          <div className="hero-content text-center m-auto">
            {/* Add your content for the second slide */}
          </div>
        </div>
        <div className="hero-item hero-item3">
          <div className="hero-content text-center m-auto">
            {/* Add your content for the third slide */}
          </div>
        </div>
        <div className="hero-item hero-item4">
          <div className="hero-content text-center m-auto">
            {/* Add your content for the fourth slide */}
          </div>
        </div>
        {/* Add more slides as needed */}
      </Slider>
    </div>
  );
};

export default HeroSlider;
