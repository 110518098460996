import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Header from "../components/Header";
import { saveShippingAddress } from "../Redux/Actions/cartActions";
import Login from "./Login"; // Import the Login component
import Modal from "react-modal"; // You'll need to install react-modal or use any other modal library

// Ensure that the app element is set for accessibility
Modal.setAppElement("#root");

// Define custom styles for the modal with responsive design
const getCustomStyles = () => ({
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '90%', // Default width
    maxWidth: '500px', // Default maxWidth
    height: 'auto', // Default height
    padding: '20px',
    borderRadius: '10px',
    transition: 'all 0.3s ease-in-out', // Smooth transition for resizing
  },
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.75)'
  }
});

// Define a function to update styles dynamically based on screen size
const updateStyles = () => {
  const styles = getCustomStyles();
  if (window.innerWidth < 600) {
    styles.content.width = '100%';
    styles.content.maxWidth = '800px';
  } else {
    styles.content.width = '100%';
    styles.content.maxWidth = '1000px';
  }
  return styles;
};

const ShippingScreen = () => {
  window.scrollTo(0, 0);
  const history = useHistory();

  const cart = useSelector((state) => state.cart);
  const { shippingAddress } = cart;

  const [address, setAddress] = useState(shippingAddress.address || "");
  const [city, setCity] = useState(shippingAddress.city || "");
  const [postalCode, setPostalCode] = useState(shippingAddress.postalCode || "");
  const [country, setCountry] = useState(shippingAddress.country || "");

  const dispatch = useDispatch();

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(saveShippingAddress({ address, city, postalCode, country }));
    history.push("/placeorder");
  };

  // Check if user is logged in
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  // State for modal visibility
  const [isModalOpen, setIsModalOpen] = useState(!userInfo);

  // Callback for successful login
  const handleLoginSuccess = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    if (userInfo) {
      setIsModalOpen(false);
    }
  }, [userInfo]);

  // Update modal styles on window resize
  const [modalStyles, setModalStyles] = useState(updateStyles());

  useEffect(() => {
    const handleResize = () => {
      setModalStyles(updateStyles());
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
      <Header />
      <Modal
        isOpen={isModalOpen}
        onRequestClose={() => setIsModalOpen(false)}
        style={modalStyles}
      >
        <Login onSuccess={handleLoginSuccess} />
      </Modal>
      <div className="container d-flex justify-content-center align-items-center login-center">
        <form
          className="Login col-md-8 col-lg-4 col-11"
          onSubmit={submitHandler}
        >
          <h6 style={{ color: "white" }}>DELIVERY ADDRESS</h6>
          <input
            type="text"
            placeholder="Enter address"
            value={address}
            required
            onChange={(e) => setAddress(e.target.value)}
          />
          <input
            type="text"
            placeholder="Enter city"
            value={city}
            required
            onChange={(e) => setCity(e.target.value)}
          />
          <input
            type="text"
            placeholder="Enter postal code"
            value={postalCode}
            required
            onChange={(e) => setPostalCode(e.target.value)}
          />
          <input
            type="text"
            placeholder="Enter country"
            value={country}
            required
            onChange={(e) => setCountry(e.target.value)}
          />
          <button type="submit">Continue</button>
        </form>
      </div>
    </>
  );
};

export default ShippingScreen;
