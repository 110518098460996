import React from "react";
import { Link } from "react-router-dom";
import Header from "./../components/Header";

const ContactUs = () => {
  return (
    <>
      <Header />
      <div className="container my-5">
        <div className="row justify-content-center align-items-center">
          <h4 className="text-center mb-2 mb-sm-5">Contact Us</h4>
          <div className="col-md-8">
            <p>
              <h5><strong>Kindly Contact Pikklez</strong></h5>
            </p><br></br>
            <p>
              We at Pikklez cherish the relationships we have with our clients. We would be happy to hear from you if you have any questions, comments, or simply to say hi. Kindly get in contact via any of the methods listed below, and let's begin a dialogue.
            </p>
            <p><br></br>
              <strong>Client Assistance</strong>
            </p><br></br>
            <p>
              We have a committed customer support team available to help you with any questions you may have. We are available to help make your experience with Pikklez effortless, from order support to product information.
            </p>
            <p><br></br>
              <strong>Email:</strong> info@pikklez.com
            </p><br></br>
            <p>
              <strong>Mobile & whatsapp:</strong> 6381295900
            </p><br></br>
            <p>
              Business hours are 9AM to 5PM.
            </p>
          </div>

          <div className="col-md-8 text-center mt-5">
            <button className="btn btn-success">
              <Link to="/" className="text-white text-decoration-none">
                Home page
              </Link>
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactUs;
