import React from "react";
import { Link, useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";

const Footer = () => {
  return (
    <div>
      <footer className="footer-distributed">
        <div className="footer-section">
          <h2 style={{ color: "#F9A81B" }}>Pikklez</h2>
          <p className="footer-links">
            <a href="/" className="link-1">
              Home
            </a>
            <a href="/"></a>

            <a href="/aboutUs">About Us</a>

            <a href="/"></a>

            <a href="/contactUs">Contact Us</a>
          </p>
          {/* <p className="footer-company-name">Company Name © 2015</p> */}
        </div>

        <div className="footer-section">
          <div>
            <i style={{ color: "#ffffff" }} className="fa fa-map-marker"></i>
            <p style={{ color: "#ffffff" }}>
              <span>Sri Sakthi Nagar</span> Kaspapettai, Erode - 638115.
            </p>
          </div>
          <div>
            <a
              href="https://api.whatsapp.com/send?phone=6381295900"
              target="_blank"
              rel="noopener noreferrer"
              className="whatsapp-icon-footer"
            >
              {" "}
              <FontAwesomeIcon icon={faWhatsapp} style={{ color: "#25D366" }} />
            </a>
            <p style={{ color: "#ffffff" }}>+91 6381295900</p>
          </div>
          <div>
            <i style={{ color: "#ffffff" }} className="fa fa-envelope"></i>
            <p>
              <a style={{ color: "#ffffff" }} href="mailto:contact@pikklez.in">
                contact@pikklez.in
              </a>
            </p>
          </div>
        </div>

        <div className="footer-section">
          <p className="footer-company-about">
            <h3 style={{ color: "#E7ED2C" }}>About the company</h3>
            Introducing Pikkelz,<br></br>where taste takes center stage! Founded in<br></br>
            2023, we're on a mission to redefine everyday dining experiences.<br></br>
            Our secret? A dedication to vibrant flavors without compromising on<br></br>
            quality. With Pikkelz, savor the essence of freshness and simplicity<br></br>
            in every bite.
          </p>
          <div className="footer-icons">
            <a href="https://api.whatsapp.com/send?phone=6381295900"
            target="_blank"
            >
              <i className="fab fa-whatsapp"></i>
            </a>
            <a href="https://www.instagram.com/pikklez_?igsh=MTFhMjlhdmg0cDBmYg=="
            target="_blank"
            >
              <i className="fab fa-instagram"></i>
            </a>
            
          </div>
        </div>
      </footer>

      {/* Top Header */}
      <div className="Announcement ">
        <div className="container">
          <div className="row">
            <div className="col-md-6 d-flex align-items-center display-none text-center">
              <p>Copyright © 2024 - pikklez.in </p>
            </div>
            {/* <div className=" col-12 col-lg-6 justify-content-center justify-content-lg-end d-flex align-items-center">
              <Link to="">
                <i className="fab fa-facebook-f"></i>
              </Link>
              <Link to="">
                <i className="fab fa-instagram"></i>
              </Link>
              <Link to="">
                <i className="fab fa-linkedin-in"></i>
              </Link>
              <Link to="">
                <i className="fab fa-youtube"></i>
              </Link>
              <Link to="">
                <i className="fab fa-pinterest-p"></i>
              </Link>
            </div> */}
          </div>
        </div>
      </div>
      {/* <div className="footer">
      <div className="justify-content-center d-flex">
        <div className="card-name">
          <img
            alt="mastercard"
            src="https://upload.wikimedia.org/wikipedia/commons/thumb/b/b7/MasterCard_Logo.svg/1200px-MasterCard_Logo.svg.png"
          />
        </div>
        <div className="card-name">
          <img
            alt="visa"
            src="https://upload.wikimedia.org/wikipedia/commons/4/41/Visa_Logo.png"
          />
        </div>
        <div className="card-name">
          <img
            alt="paypal"
            src="https://1000logos.net/wp-content/uploads/2021/04/Paypal-logo-1536x864.png"
          />
        </div>
        <div className="card-name">
          <img
            alt="express"
            src="https://icons.iconarchive.com/icons/designbolts/credit-card-payment/256/American-Express-icon.png"
          />
        </div>
        <div className="card-name">
          <img
            alt="discover"
            src="https://icons-for-free.com/iconfiles/png/512/cash+checkout+discover+network+online+shopping+payment+method-1320191225548835050.png"
          />
        </div>
      </div>
    </div> */}
    </div>
  );
};

export default Footer;
